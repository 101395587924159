import { render, staticRenderFns } from "./AddContactTargetModal.vue?vue&type=template&id=9153cee2&scoped=true&"
import script from "./AddContactTargetModal.vue?vue&type=script&lang=ts&"
export * from "./AddContactTargetModal.vue?vue&type=script&lang=ts&"
import style0 from "./AddContactTargetModal.vue?vue&type=style&index=0&id=9153cee2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9153cee2",
  null
  
)

export default component.exports