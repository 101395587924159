

































































































































































































import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import { ApiResponse, Contact, Pagination, Sale } from '@/interfaces'
import { mapState } from 'vuex'
import AddContactTargetModal from '@/components/AddContactTargetModal.vue'

interface ContactActionObject {
  action: string
  contact: Contact
}

interface TargetObject {
  value: string | null
  label: string | null
}

interface SelectOption {
  value: string | null
  label: string | null
}

interface SortObject {
  order: string
  prop: string
}

interface Parameter {
  search?: string | null
  target?: string[] | null
  category?: string[] | null
  page?: string | null
  order?: string | null
  orderby?: string | null
  sale?: string | null
}

interface DropdownAction {
  action: string
}

export default Vue.extend({
  components: {
    Nav,
    AddContactTargetModal,
  },
  data() {
    return {
      contacts: [],
      search: '',
      page: '1',
      activePage: 1,
      itemsTotal: 0,
      numPages: 1,
      orderBy: null as null | string,
      order: null as null | string,
      busy: false,
      targetFilter: [] as string[],
      categoryFilter: null as string[] | null,
      contactCategories: null as SelectOption[] | null,
      targets: null as SelectOption[] | null,
      selectedIds: [] as number[],
      addons: null,
      sales: [] as Record<string, string | null>[],
      saleFilter: null as null | string,
      toggleTargetModal: false,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    user(newVal) {
      this.contactCategories = newVal.client.config.categories as SelectOption[]
      this.targets = newVal.client.config.targets as SelectOption[]

      if (this.$route.query.target && this.$route.query.target !== '') {
        if (typeof this.$route.query.target == 'string') {
          this.targetFilter = [this.$route.query.target]
        } else {
          this.targetFilter = this.$route.query.target as string[]
        }
      }

      this.addons = newVal.client?.addons ?? null
    },
  },
  mounted() {
    if (this.$route.query.search && this.$route.query.search !== '') {
      this.search = this.$route.query.search as string
    }

    if (this.$route.query.page) {
      this.page = this.$route.query.page as string
    }

    if (this.$route.query.search) {
      this.search = this.$route.query.search as string
    }

    if (this.$route.query.orderby) {
      this.orderBy = this.$route.query.orderby as string
    }

    if (this.$route.query.order) {
      this.order = this.$route.query.order as string
    }

    if (this.$route.query.category && this.$route.query.category !== '') {
      this.categoryFilter = this.$route.query.category as string[]
    }

    if (this.$route.query.sale) {
      // pour que l'id soit connnu, besoin de charger les ventes dans ce cas car elle ne sont pas préchargées par défaut
      this.loadSales()
      this.saleFilter = this.$route.query.sale as string
    }

    if (this.user) {
      this.contactCategories = this.user.client.config.categories as SelectOption[]
      this.targets = this.user.client.config.targets as SelectOption[]
      this.addons = this.user.client?.addons ?? null
    }

    if (this.$route.query.target && this.$route.query.target !== '') {
      if (typeof this.$route.query.target == 'string') {
        this.targetFilter = [this.$route.query.target]
      } else {
        this.targetFilter = this.$route.query.target as string[]
      }
    }

    this.getContacts()
  },
  methods: {
    addonTest(code: number) {
      // Hack en attendant d'avoir récupéré le code addon du client, on affiche
      // puis on cache apres, plutot que l'inverse sinon bug d'affichage sur la colonne de sélection du tableau
      if (this.addons == null) {
        return true
      }
      return BigInt(code) & BigInt(this.addons)
    },
    updateRoute(resetPage = false) {
      if (resetPage) {
        this.page = '1'
      }

      let query = {} as Parameter
      if (this.targetFilter) {
        query.target = this.targetFilter
      } else {
        delete query.target
      }
      if (this.categoryFilter !== null) {
        query.category = this.categoryFilter
      } else {
        delete query.category
      }

      //console.log("search = " + this.search)

      // Setting query URL
      query = {
        //...query,
        search: this.search,
        target: this.targetFilter,
        category: this.categoryFilter,
        sale: this.saleFilter,
        page: this.page,
        orderby: this.orderBy,
        order: this.order,
      }

      // Change the URL whithout reload ==> A finir car au retour tout s'efface... compliqué :/
      let queryString = this.createSearchParams(query).toString()
      //console.log("queryString = " + queryString)
      history.pushState(null, '', `${this.$route.path}?${queryString}`)
      this.getContacts()

      // Autre methode mais recharche tout le composant donc on perds les datas
      //this.$router.push({ query: Object.assign({}, this.$route.query, query) }).catch(() => {
        // This empty catch method is here to avoid the "Avoided redundant navigation" error
      //})
    },
    changePage(page: string) {
      this.page = page
      this.updateRoute()
    },
    // Sortable retiré des colonnes car buggue (cf API) et utilité discutable.
    sortChange(sort: SortObject) {
      this.order = null
      this.orderBy = null

      switch (sort.order) {
        case 'ascending':
          this.order = 'ASC'
          break

        case 'descending':
          this.order = 'DESC'
          break

        default:
          this.order = 'DESC'
          break
      }

      this.orderBy = sort.prop

      // Setting query URL
      const query = {
        search: this.search,
        target: this.targetFilter,
        category: this.categoryFilter,
        page: this.page,
        orderby: this.orderBy,
        order: this.order,
        sale: this.saleFilter,
      }

      this.$router.push({ query: Object.assign({}, this.$route.query, query) }).catch(() => {
        // This empty catch method is here to avoid the "Avoided redundant navigation" error
      })
    },
    getContacts() {
      if (!this.busy) {
        this.busy = true

        const params = {} as Parameter
        // if (this.search) {
        //   params.search = this.search
        // } else {
        //   delete params.search
        // }
        // if (this.targetFilter) {
        //   params.target = this.targetFilter
        // } else {
        //   delete params.target
        // }
        // if (this.categoryFilter !== null) {
        //   params.category = this.categoryFilter
        // } else {
        //   delete params.category
        // }

        // Setting query URL
        // this.$router
        //   .push({
        //     query: Object.assign({}, this.$route.query, params),
        //   })
        //   .catch(() => {
        //     // This empty catch method is here to avoid the "Avoided redundant navigation" error
        //   })

        const loading = this.$loading({
          target: '#contactsTable',
          text: 'Chargement des données...',
        })

        this.$api
          .get('/contacts', {
            params: {
              search: this.search,
              target: this.targetFilter,
              category: this.categoryFilter,
              page: this.page,
              orderby: this.orderBy,
              order: this.order,
              sale: this.saleFilter,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.contacts = apiResponse.data.contacts

            const pagination = apiResponse.data.pagination as Pagination

            this.activePage = pagination.current
            this.itemsTotal = pagination.totalCount
            this.numPages = pagination.pageCount
          })
          .finally(() => {
            loading.close()
            this.busy = false
          })
      }
    },
    editContact(contact: Contact) {
      if (contact.id) {
        this.$router.push({
          name: 'contact_edit',
          params: {
            id: contact.id.toString(),
          },
        })
      }
    },
    deleteContact(contact: Contact) {
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir supprimer ce contact ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        this.$api
          .delete(`/contact/${contact.id}`)
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Opération réalisée avec succès !',
            })

            this.getContacts()
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Merci de reéssayer plus tard.',
            })
          })
      })
    },
    contactAction(args: ContactActionObject) {
      if (args.action === 'edit') {
        this.editContact(args.contact)
      }

      if (args.action === 'delete') {
        this.deleteContact(args.contact)
      }
    },
    getCategory(category: string): string {
      return this.contactCategories?.find((item: SelectOption) => item.value === category)?.label ?? 'Contact'
    },
    getTypeIcon(type: string): string {
      switch (type) {
        case 'personne_physique':
          return 'contact-type-icon el-icon-user'

        case 'personne_morale':
          return 'contact-type-icon el-icon-suitcase-1'

        default:
          return 'contact-type-icon el-icon-question'
      }
    },
    handleFilter(value: string, row: Record<string, string | number>, column: Record<string, string>) {
      const property = column['property']

      return row[property] === value
    },
    //goToEditContact(row: Contact, column: Record<string, string>, cell: HTMLTableCellElement) {
    goToEditContact(row: Contact) {
      //if (!cell.classList.contains('actions')) {
        this.$router.push({
          name: 'contact_edit',
          params: {
            id: row.id?.toString() ?? '',
          },
        })
      //}
    },
    // handleItemCommand(command: DropdownAction) {
    //   // Renumber items
    //   if (command.action == 'export') {
    //     this.exportContacts()
    //   }
    // },
    handleItemCommand(command: string) {
      switch (command) {
        case 'massTarget':
          this.toggleTargetModal = !this.toggleTargetModal
        break
        case 'export' :
          this.exportContacts()
        break
      }
    },
    exportContacts() {
      // Get all contacts with this filter
      this.busy = true
      this.$api
        .get('/contacts/export', {
          params: {
            search: this.search,
            target: this.targetFilter,
            category: this.categoryFilter,
            sale: this.saleFilter,
          },
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          if (apiResponse.success === true) {
            const documentUrl = `${process.env.VUE_APP_API_URL}/document/${apiResponse.data.filename}/download_once`
            window.open(documentUrl, '_blank')
          }
        })
        .finally(() => {
          this.busy = false
        })
    },
    getTargetLabel(target: string) {
      if (this.user) {
        const targetObjects = this.user.client.config.targets as TargetObject[]
        return targetObjects.find((item: TargetObject) => item.value === target)?.label ?? target
      }
    },
    handleSelectionChange(selectedItems: Contact[]) {
      this.selectedIds = []
      selectedItems.forEach((selectedItem) => {
        if (selectedItem?.id) {
          this.selectedIds?.push(selectedItem.id)
        }
      })
    },
    getSummary() {
      const count = this.selectedIds?.length ?? 0
      const countDocuments = this.itemsTotal
      let summary = countDocuments + ' contact(s)'
      if (count) {
        summary += ', ' + count + ' sélectionné(s) ☆'
      }
      return summary
    },
    loadSales() {
      if (this.sales.length > 0) {
        return
      }
      this.sales = []
      const now = new Date();
      this.$api.get('/sales?type=simple').then((response) => {
        const apiResponse = response.data as ApiResponse

        apiResponse.data.forEach((sale: Sale) => {
          if (sale.status && sale.status !== 'comptabilise') {
            this.sales.push({
              label: this.getSaleTitle(sale),
              value: sale?.id?.toString() ?? null,
            })

          }
        })
      })
    },
    getSaleTitle(sale: Sale): string {
      let saleTitle = ''
      if (sale?.title) {
        saleTitle += ' ' + sale?.title
      }
      if (sale?.startDate) {
        const date = Date.parse(sale?.startDate)
        if (date) {
          saleTitle += ' - ' + new Intl.DateTimeFormat('fr-FR').format(date)
        }
      }
      return saleTitle !== '' ? saleTitle : '-'
    },
    createSearchParams(params: Parameter) {
      const searchParams = new URLSearchParams()
      Object.entries(params).forEach(([key, values]) => {
        if (Array.isArray(values)) {
          values.forEach((value) => {
            searchParams.append(key, value)
          })
        } else if (values != null) {
          let stringValue = values.toString()
          searchParams.append(key, stringValue)
        }
      })
      return searchParams
    },
  },
})
