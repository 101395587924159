










































import Vue from 'vue'
import { ApiResponse, RelatedSaleItem } from '@/interfaces'
import { mapState } from 'vuex'

interface RegroupData {
  itemNumber: string[] | null
}

export default Vue.extend({
  props: {
    toggleModal: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: false,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      showModal: false,
      userTargets: null as [] | null,
      targetId: '' as string,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    toggleModal(newVal) {
      this.showModal = true
    },
    user(newVal) {
      this.userTargets = newVal.client.config.targets
    },
  },
  mounted() {
    if (this.user) {
      this.userTargets = this.user.client.config.targets
    }
  },
  methods: {
    submit(mode: string) {
      const postData = {
        ids: this.items,
        addTarget: '',
        removeTarget: '',
      }
      if (mode == 'add') {
        postData.addTarget = this.targetId
      }
      if (mode == 'remove') {
        postData.removeTarget = this.targetId
      }
      this.$api
        .post(`/contacts/mass-update`, postData)
        .then((response) => {
          const apiResponse = response.data as ApiResponse
          this.$emit('refresh')
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse
            // On ne teste que le plantage API
            if (error.response?.status == 500) {
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          }
        })
        .finally(() => {
          this.closeModal()
        })
    },
    closeModal() {
      this.showModal = false
    },

  },
})
